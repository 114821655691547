.label {
  @apply
  flex
  space-x-1
  items-center
  whitespace-nowrap;

  &.md {
    @apply text-body-2 rounded-4xl py-1.5 px-2.5;

    figure {
      height: 16px;
      width: 16px;

      @apply box-content;

      i {
        @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
      }

      &:only-child {
        width: 16px;
      }
    }
  }

  &.sm {
    @apply text-caption rounded-3xl py-1 px-2;

    figure {
      height: 16px;
      width: 16px;

      @apply box-content;

      i {
        @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
      }

      &:only-child {
        width: 16px;
      }
    }
  }

  &.label-primary {
    &.inline {
      @apply text-primary-A400 bg-primary-25 ;
    }

    &.outline {
      @apply text-primary-A400 bg-white border border-blue-grey-50;
    }
  }

  &.label-green {
    &.inline {
      @apply text-secondary-600 bg-secondary-25;
    }

    &.outline {
      @apply text-secondary-600 bg-white border border-blue-grey-50;
    }
  }

  &.label-gray {
    &.inline {
      @apply text-blue-grey-400 bg-blue-grey-25;
    }

    &.outline {
      @apply text-blue-grey-400 bg-white border border-blue-grey-50;
    }
  }

  &.label-tertiary {
    &.inline {
      @apply text-tertiary-A400 bg-tertiary-25;
    }

    &.outline {
      @apply text-tertiary-A400 bg-white border border-blue-grey-50;
    }
  }

  &.label-icon-primary {
    width: 16px;
    height: 16px;

    @apply rounded p-0;

    &.inline {
      @apply text-primary-A400 bg-primary-25;
    }

    &.outline {
      @apply text-primary-A400 bg-white border border-blue-grey-50;
    }
  }

  &.label-icon-green {
    width: 16px;
    height: 16px;

    @apply rounded p-0;

    &.inline {
      @apply text-secondary-600 bg-secondary-25;
    }

    &.outline {
      @apply text-secondary-600 bg-white border border-blue-grey-50;
    }
  }

  &.label-icon-gray {
    width: 16px;
    height: 16px;

    @apply rounded p-0;

    &.inline {
      @apply text-blue-grey-400 bg-blue-grey-25;
    }

    &.outline {
      @apply text-blue-grey-400 bg-white border border-blue-grey-50;
    }
  }

  &.label-icon-tertiary {
    width: 16px;
    height: 16px;

    @apply rounded p-0;

    &.inline {
      @apply text-tertiary-A400 bg-tertiary-25;
    }

    &.outline {
      @apply text-tertiary-A400 bg-white border border-blue-grey-50;
    }
  }
}
