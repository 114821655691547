.overlay {
  &.overlay-gray {
    background: rgba(38, 50, 56, 0.6);
  }

  &.overlay-primary {
    background: rgba(41, 121, 255, 0.8);
    backdrop-filter: blur(10px);
  }
}
