/* Plugin styles */

.tc-wrap {
  .tc-toolbox {
    &.tc-toolbox--column,
    &.tc-toolbox--row {
      &.tc-toolbox--showed {
        .tc-popover.tc-popover--opened {
          .tc-popover__item.tc-popover__item--confirm {
            .tc-popover__item-icon {
              svg {
                fill: var(--white);
              }
            }

            .tc-popover__item-label {
              @apply text-white;
            }
          }
        }
      }
    }
  }
}
