.error-text {
  .run-line {
    animation-name: run-line;
    animation-duration: calc(var(--duration));
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @keyframes run-line {
      0% {
        @apply left-0;
      }
      50% {
        left: var(--shift);
      }
      100% {
        @apply left-0;
      }
    }
  }
}

.peer:not(:focus):placeholder-shown ~ .peer-placeholder-shown\:label-placeholder-shown {
  .error-text {
    @apply overflow-ellipsis;
    .run-line {
      @apply animate-none;
    }
  }
}
