@layer components {
  .border-shadow-default {
    box-shadow: inset 0 0 0 1px var(--blue-grey-50);
  }

  .border-shadow-focus {
    box-shadow: inset 0 0 0 1px var(--primary-A400);
  }

  .border-shadow-error {
    box-shadow: inset 0 0 0 1px var(--tertiary-A400);
  }

  .border-shadow-transparent {
    box-shadow: inset 0 0 0 1px var(--transparent);
  }

  .effect-00dp {
    box-shadow: 0 4px 4px rgba(38, 50, 56, 0.1);
  }

  .effect-01dp {
    box-shadow: 0 1px 1px rgba(38, 50, 56, 0.04), 0 2px 1px rgba(38, 50, 56, 0.04), 0 1px 3px rgba(38, 50, 56, 0.08);
  }

  .effect-02dp {
    box-shadow: 0 2px 2px rgba(38, 50, 56, 0.14), 0 3px 1px rgba(38, 50, 56, 0.12), 0 1px 5px rgba(38, 50, 56, 0.2);
  }

  .effect-04dp {
    box-shadow: 0 4px 5px rgba(38, 50, 56, 0.14), 0 1px 10px rgba(38, 50, 56, 0.12), 0 2px 4px rgba(38, 50, 56, 0.2);
  }

  .effect-06dp {
    box-shadow: 0 6px 10px rgba(38, 50, 56, 0.14), 0 1px 18px rgba(38, 50, 56, 0.12), 0 3px 5px rgba(38, 50, 56, 0.2);
  }

  .effect-08dp {
    box-shadow: 0 8px 10px rgba(38, 50, 56, 0.14), 0 3px 14px rgba(38, 50, 56, 0.12), 0 5px 5px rgba(38, 50, 56, 0.2);
  }

  .effect-16dp {
    box-shadow: 0 12px 17px rgba(38, 50, 56, 0.14), 0 5px 22px rgba(38, 50, 56, 0.12), 0 7px 8px rgba(38, 50, 56, 0.2);
  }

  .effect-32dp {
    box-shadow: 0 24px 38px rgba(41, 121, 255, 0.14), 0 9px 46px rgba(41, 121, 255, 0.12), 0 11px 15px rgba(41, 121, 255, 0.2);
  }
}
