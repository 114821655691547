* {
  @screen mouse {
    $color-track: transparent;
    $color-thumb: var(--blue-grey-50);

    scrollbar-color: $color-thumb $color-track;

    &::-webkit-scrollbar {
      @apply w-1.5 h-1.5 cursor-default;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-track;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded;

      background-color: $color-thumb;
    }

    &::-webkit-scrollbar-corner {
      background: $color-track;
    }
  }
}
