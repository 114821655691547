.app-ui {
  display: grid;
  grid-template-areas:
    'app-header app-header'
    'app-aside app-content'
    'app-footer app-footer';
  grid-template-columns: minmax(0, theme('spacing.64')) minmax(0, 1fr);
  grid-template-rows: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);

  .app-header {
    grid-area: app-header;
  }

  .app-aside {
    grid-area: app-aside;
  }

  .app-content {
    grid-area: app-content;
  }

  .app-footer {
    grid-area: app-footer;
  }
}
