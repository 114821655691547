.btn {
  @apply
  flex
  space-x-2
  items-center
  whitespace-nowrap
  transition-all
  ease-linear
  duration-100;

  &.md {
    @apply text-button-1 py-2.5 px-4;

    figure {
      // same as line-height of .text-button-1

      height: 20px;
      width: 20px;

      @apply box-content;

      i {
        @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
      }

      &:only-child {
        width: 8px;
      }
    }
  }

  &.sm {
    @apply text-button-2 py-2 px-2.5;

    figure {
      // same as line-height of .text-button-2

      height: 16px;
      width: 16px;

      i {
        @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
      }

      &:only-child {
        width: 12px;
      }
    }
  }

  &.btn-primary {
    &.inline {
      @apply text-white bg-primary-A400 rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-primary-800;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.outline {
      @apply text-primary-A400 bg-transparent rounded-4xl;

      box-shadow: inset 0 0 0 1px var(--primary-A400);

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-primary-50;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.transparent {
      @apply text-primary-A400 bg-transparent rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply text-primary-800;
        }
      }

      &:disabled,
      &.disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }
  }

  &.btn-gray {
    &.inline {
      @apply text-blue-grey-400 bg-blue-grey-50 rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-blue-grey-100;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.outline {
      @apply text-blue-grey-400 bg-transparent rounded-4xl;

      box-shadow: inset 0 0 0 1px var(--blue-grey-100);

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-blue-grey-50;

          box-shadow: inset 0 0 0 1px var(--blue-grey-400);
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.transparent {
      @apply text-blue-grey-400 bg-transparent rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply text-blue-grey-600;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }
  }

  &.btn-light-gray {
    &.transparent {
      @apply text-blue-grey-200 bg-transparent rounded;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply text-blue-grey-400;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }
  }

  &.btn-on-primary {
    &.inline {
      @apply text-primary-A400 bg-white rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-primary-25;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.outline {
      @apply text-white bg-transparent rounded-4xl;

      box-shadow: inset 0 0 0 1px var(--white);

      &:not(:disabled) {
        &.active,
        &:hover {

        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.transparent {
      @apply text-white bg-transparent rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {

        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }
  }

  &.btn-tertiary {
    &.inline {
      @apply text-white bg-tertiary-A400 rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-tertiary-800;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.outline {
      @apply text-tertiary-A400 bg-white rounded-4xl;

      box-shadow: inset 0 0 0 1px var(--tertiary-A400);

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply bg-tertiary-50;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }

    &.transparent {
      @apply text-tertiary-A400 bg-transparent rounded-4xl;

      &:not(:disabled) {
        &.active,
        &:hover {
          @apply text-tertiary-800;
        }
      }

      &:disabled {
        @apply opacity-25 cursor-not-allowed;
      }
    }
  }
}
