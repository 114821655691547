/* Plugin styles */

.image-tool {
  @apply flex flex-col items-start;
  --bg-color: #cdd1e0;
  --front-color: #388ae5;
  --border-color: #e8e8eb;

  .cdx-button {
    @apply flex items-center justify-center w-full border-blue-grey-50 rounded-md p-4;

    svg {
      height: 14px;
      margin-top: 0;
      margin-right: 8px;
    }

    &:hover {
      color: #393f52;
    }
  }

  .image-tool__image {
    @apply rounded p-0 mb-2;

    .image-tool__image-picture {
      @apply rounded block w-max align-bottom;
    }

    .image-tool__image-preloader {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--bg-color);
      @apply relative bg-cover bg-center m-auto;

      &::after {
        content: "";
        z-index: 3;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid var(--bg-color);
        border-top-color: var(--front-color);
        margin-top: -30px;
        margin-left: -30px;
        animation: image-preloader-spin 2s infinite linear;
        @apply absolute top-1/2 left-1/2 box-border;
      }
    }
  }

  .image-tool__caption {
    @apply
    w-full
    text-body-2
    rounded-none
    shadow-none
    py-0 px-2
    border-t-0
    border-b-0
    border-r-0
    border-blue-grey-100
    text-blue-grey-400;

    //--caption-width: 100%;
    //max-width: var(--caption-width);
    //min-width: 300px;

    &[contentEditable="true"][data-placeholder]::before {
      position: absolute !important;
      content: attr(data-placeholder);
      color: #707684;
      @apply hidden font-normal;
    }

    &[contentEditable="true"][data-placeholder]:empty {
      &::before {
        @apply block;
      }

      &:focus::before {
        @apply hidden;
      }
    }
  }
}

.image-tool.image-tool--empty {
  .image-tool__image {
    @apply hidden;
  }
}

.image-tool.image-tool--empty,
.image-tool.image-tool--loading {
  .image-tool__caption {
    @apply hidden;
  }
}

.image-tool.image-tool--filled {
  .cdx-button {
    @apply hidden;
  }

  .image-tool__image {
    &-preloader {
      @apply hidden;
    }
  }
}

.image-tool.image-tool--loading {
  .image-tool__image {
    width: 100%;
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;

    &-picture {
      @apply hidden;
    }
  }

  .cdx-button {
    @apply hidden;
  }
}

/**
   * DabsterImageTunes
   * ----------------
   */

.image-tool.image-tool--withBorder {
  .image-tool__image-picture {
    @apply border border-blue-grey-50 effect-01dp;
  }
}

.image-tool.image-tool--withBackground {
  .image-tool__image {
    @apply bg-blue-grey-25 w-full p-4;

    &-picture {
      @apply my-0 mx-auto;
    }
  }

  .image-tool__caption {
    @apply max-w-full;
  }
}

.image-tool.image-tool--stretched {
  .image-tool__image {
    @apply w-full;
    &-picture {
      @apply w-full;
    }
  }

  .image-tool__caption {
    @apply max-w-full;
  }
}

.image-tool.image-tool--alignLeft {
  @apply items-start;
}

.image-tool.image-tool--alignCenter {
  @apply items-center;
}

.image-tool.image-tool--alignRight {
  @apply items-end;
}

@keyframes image-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* KW styles */

.image-tool {
  &.image-tool--empty {
    .cdx-button {
      @apply overflow-hidden border border-blue-grey-50 text-blue-grey-300 rounded effect-00dp p-3;

      &:hover {
        @apply bg-blue-grey-25 text-blue-grey-900;

        svg {
          @apply opacity-100;
        }
      }

      svg {
        @apply opacity-50;
      }
    }
  }
}
