$header-height: 3.5rem;
$content-max-width: 65rem;

@mixin theme-light {
  --black: #000;
  --white: #FFF;
  --transparent: transparent;

  --primary-900: #0D47A1;
  --primary-800: #1565C0;
  --primary-700: #1976D2;
  --primary-600: #1E88E5;
  --primary-500: #2196F3;
  --primary-400: #42A5F5;
  --primary-300: #64B5F6;
  --primary-200: #90CAF9;
  --primary-100: #BBDEFB;
  --primary-50: #E3F2FD;
  --primary-25: #F0F8FD;
  --primary-A700: #2962FF;
  --primary-A400: #2979FF;
  --primary-A200: #448AFF;
  --primary-A100: #82B1FF;

  --secondary-900: #005213;
  --secondary-800: #00741B;
  --secondary-700: #049625;
  --secondary-600: #10B837;
  --secondary-500: #21DA4B;
  --secondary-400: #35FC63;
  --secondary-300: #63FF87;
  --secondary-200: #9BF9B0;
  --secondary-100: #C1F2CC;
  --secondary-50: #EDF9F0;
  --secondary-25: #F6FBF7;

  --tertiary-900: #880E4F;
  --tertiary-800: #AD1457;
  --tertiary-700: #C2185B;
  --tertiary-600: #D81B60;
  --tertiary-500: #E91E63;
  --tertiary-400: #EC407A;
  --tertiary-300: #F06292;
  --tertiary-200: #F48FB1;
  --tertiary-100: #F8BBD0;
  --tertiary-50: #FCE4EC;
  --tertiary-25: #FDF1F5;
  --tertiary-A700: #C51162;
  --tertiary-A400: #F50057;
  --tertiary-A200: #FF4081;
  --tertiary-A100: #FF80AB;

  --blue-grey-900: #263238;
  --blue-grey-800: #37474F;
  --blue-grey-700: #455A64;
  --blue-grey-600: #546E7A;
  --blue-grey-500: #607D8B;
  --blue-grey-400: #78909C;
  --blue-grey-300: #90A4AE;
  --blue-grey-200: #B0BEC5;
  --blue-grey-100: #CFD8DC;
  --blue-grey-50: #ECEFF1;
  --blue-grey-25: #F7F9F9;
}

@mixin theme-dark {
  --black: #000;
  --white: #FFF;
  --transparent: transparent;

  --primary-900: #0D47A1;
  --primary-800: #1565C0;
  --primary-700: #1976D2;
  --primary-600: #1E88E5;
  --primary-500: #2196F3;
  --primary-400: #42A5F5;
  --primary-300: #64B5F6;
  --primary-200: #90CAF9;
  --primary-100: #BBDEFB;
  --primary-50: #E3F2FD;
  --primary-25: #F0F8FD;
  --primary-A700: #2962FF;
  --primary-A400: #2979FF;
  --primary-A200: #448AFF;
  --primary-A100: #82B1FF;

  --secondary-900: #005213;
  --secondary-800: #00741B;
  --secondary-700: #049625;
  --secondary-600: #10B837;
  --secondary-500: #21DA4B;
  --secondary-400: #35FC63;
  --secondary-300: #63FF87;
  --secondary-200: #9BF9B0;
  --secondary-100: #C1F2CC;
  --secondary-50: #EDF9F0;
  --secondary-25: #F6FBF7;

  --tertiary-900: #880E4F;
  --tertiary-800: #AD1457;
  --tertiary-700: #C2185B;
  --tertiary-600: #D81B60;
  --tertiary-500: #E91E63;
  --tertiary-400: #EC407A;
  --tertiary-300: #F06292;
  --tertiary-200: #F48FB1;
  --tertiary-100: #F8BBD0;
  --tertiary-50: #FCE4EC;
  --tertiary-25: #FDF1F5;
  --tertiary-A700: #C51162;
  --tertiary-A400: #F50057;
  --tertiary-A200: #FF4081;
  --tertiary-A100: #FF80AB;

  --blue-grey-900: #263238;
  --blue-grey-800: #37474F;
  --blue-grey-700: #455A64;
  --blue-grey-600: #546E7A;
  --blue-grey-500: #607D8B;
  --blue-grey-400: #78909C;
  --blue-grey-300: #90A4AE;
  --blue-grey-200: #B0BEC5;
  --blue-grey-100: #CFD8DC;
  --blue-grey-50: #ECEFF1;
  --blue-grey-25: #F7F9F9;
}

:root {
  @media (prefers-color-scheme: light) {
    @include theme-light;
  }

  @media (prefers-color-scheme: dark) {
    @include theme-dark;
  }
}
