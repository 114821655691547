@use "../abstracts/variables";
@use "sass:math";

$breadcrumb-height: 4rem;
$footer-height: 3.5rem;

/* Wrapper && fullscreen styles */

.skill-task-description-wrapper {
  --float-duration: 500ms;
  --float-delay: 250ms;
  --float-y: 0;
  --float-x: 0;
  --float-height: 0;
  --float-width: 0;
  --outer-width: 0;

  .skill-task-description-fade {
    @apply fixed top-0 left-0 w-full h-full pointer-events-none;

    &.fullscreen-on {
      &:not(.animation-playing) {
        @apply bg-blue-grey-25;
      }

      &.animation-playing {
        animation: fullscreen-on-fade-animation var(--float-delay);
        animation-fill-mode: forwards;

        @keyframes fullscreen-on-fade-animation {
          from {
            @apply bg-transparent;
          }
          to {
            @apply bg-blue-grey-25;
          }
        }
      }
    }

    &.fullscreen-off {
      &:not(.animation-playing) {
        @apply bg-transparent;
      }

      &.animation-playing {
        @apply bg-blue-grey-25;

        animation: fullscreen-off-fade-animation var(--float-delay) cubic-bezier(0.785, 0.135, 0.15, 0.86) var(--float-duration);
        animation-fill-mode: forwards;

        @keyframes fullscreen-off-fade-animation {
          from {
            @apply bg-blue-grey-25;
          }
          to {
            @apply bg-transparent;
          }
        }
      }
    }
  }

  .skill-task-description-outer {
    --test-translate: translateX(-#{math.div(65rem, 2)});
    --test-width: 65rem;

    @screen tablet {
      --test-translate: translateX(-50%);
      --test-width: calc(100% - 4rem);
    }

    .desc-clone {
      @apply absolute invisible;

      z-index: -1;
    }

    .result-clone {
      @apply
      fixed
      invisible
      overflow-hidden
      text-body-1
      pointer-events-none
      select-none
      p-4;

      z-index: -1;
      white-space: break-spaces;
    }

    .btn-show-more {
      @apply
      absolute
      -bottom-4 left-0
      bg-white
      w-full
      px-0.5 py-4
      flex
      items-center
      justify-start
      space-x-2
      z-10;

      span, svg > use {
        @apply
        text-button-2
        text-blue-grey-400
        opacity-100
        transition-opacity;
      }
    }

    .btn-show-more.shadow-more {
      &::after {
        @apply absolute block left-0 bg-blue-grey-50;
        width: 100%;
        height: 4px;
        bottom: 52px;
        content: "";
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(38, 50, 56, 0.15) 100%);
      }
    }

    &.fullscreen-on {
      @apply fixed;

      &:not(.animation-playing) {
        top: calc(#{variables.$header-height + $breadcrumb-height});
        left: 50%;
        transform: var(--test-translate);
        width: var(--test-width);
        height: 100%;
      }

      &.animation-playing {
        top: var(--float-y);
        left: var(--float-x);
        width: var(--float-width);
        height: var(--float-height);

        animation: fullscreen-on-animation var(--float-duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) var(--float-delay);
        animation-fill-mode: forwards;

        @keyframes fullscreen-on-animation {
          100% {
            top: calc(#{variables.$header-height + $breadcrumb-height});
            left: 50%;
            transform: var(--test-translate);
            width: var(--test-width);
            height: 100%;
          }
        }
      }

      .skill-task-description-inner {
        max-height: calc(100vh - theme('spacing.4') - #{variables.$header-height + $breadcrumb-height + $footer-height});
        @apply overflow-y-auto overflow-x-hidden;
      }

      .desc-clone {
        width: calc(var(--test-width) - theme('spacing.12'));
      }

      .result-clone {
        width: calc(var(--test-width) - theme('spacing.16'));
      }

      .btn-show-more {
        @apply opacity-0 pointer-events-none;
      }
    }

    &.fullscreen-off {
      &:not(.animation-playing) {}

      &.animation-playing {
        @apply fixed;

        top: calc(#{variables.$header-height + $breadcrumb-height});
        left: 50%;
        transform: var(--test-translate);
        width: var(--test-width);
        height: 100%;

        animation: fullscreen-off-animation var(--float-duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
        animation-fill-mode: forwards;

        @keyframes fullscreen-off-animation {
          100% {
            top: var(--float-y);
            left: var(--float-x);
            transform: translateX(0);
            width: var(--float-width);
            height: var(--float-height);
          }
        }
      }

      .skill-task-description-inner {
        .codex-editor__redactor {
          .ce-block {
            min-width: 100% !important;
          }
        }
      }

      .desc-accordion {
        height: var(--desc-height);
        transition: height var(--desc-duration);
        overflow: hidden;
      }

      .desc-clone {
        width: calc(var(--float-width) - theme('spacing.12'));
      }

      .result-clone {
        width: calc(var(--float-width) - theme('spacing.16'));
      }

      .result-accordion {
        height: var(--result-height);
        transition: height var(--result-duration);
        overflow: hidden;

        .btn-show-more {
          bottom: -32px;
        }
      }

      .result-accordion.shift {
        margin-bottom: 52px;

        .btn-show-more {
          bottom: 52px;
        }
      }
    }

    .skill-task-description-header {
      @apply absolute bottom-full;
    }
  }

  .skill-task-description-footer {
    @apply
    fixed
    left-0
    bottom-0
    w-full
    flex
    items-center
    justify-between
    bg-white
    effect-02dp
    py-2
    px-8;
  }
}

.editor-js {

  .desc-accordion,
  .desc-clone {
    & > * {
      @apply my-2;
    }
  }

  /* Apply dark color to all */

  * {
    @apply text-subtitle-1 font-normal text-blue-grey-900;
  }

  /* Embed */

  .editor-js-embed {
    @apply flex flex-col items-start mb-4;

    .editor-js-embed-wrapper {
      @apply relative w-full pt-[56.25%] mb-2;
    }

    .editor-js-embed-content {
      @apply absolute inset-0 w-full h-full rounded;
    }

    .editor-js-embed-caption {
      @apply w-full text-left text-body-2 text-blue-grey-400 rounded-none border-blue-grey-100 border-l overflow-hidden py-0 px-2;
    }
  }

  /* Headings */

  h1 {
    @apply text-headline-7 font-semibold mt-4 mb-2 p-0 #{'!important'};
  }

  h2 {
    @apply text-subtitle-1 mt-4 mb-2 p-0 #{'!important'};
  }

  p {
    @apply leading-6 min-h-[24px] mb-0;
  }

  b, b * {
    @apply font-semibold;
  }

  a:not(.editor-js-link):not(.editor-js-attaches) {
    @apply text-primary-A400 underline;

    &:hover {
      @apply text-primary-800;
    }
  }

  /* Marker tool */

  .cdx-marker {
    @apply bg-secondary-100 rounded-sm px-1;
  }

  /* Quote tool */

  blockquote {
    @apply bg-blue-grey-25 border border-blue-grey-100 rounded p-4;

    p {
      @apply min-h-0 border-0 border-b border-b-blue-grey-100 outline-none shadow-none p-0 pb-4 mb-4;

      &:before {
        content: "“";

        @apply inline-block text-blue-grey-900 opacity-100 text-center w-3;
      }

      &:after {
        content: "”";

        @apply inline-block text-blue-grey-900 opacity-100 text-center w-3;
      }
    }

    cite {
      @apply min-h-0 border-0 outline-none shadow-none text-body-2 italic p-0;

      &::before {
        content: '—';

        @apply mr-2;
      }
    }
  }

  /* List tool */

  .editor-js-ul {
    @apply list-disc pl-5;
  }

  .editor-js-ol {
    @apply list-decimal;

    counter-reset: item;

    .editor-js-li {
      @apply block;

      counter-increment: item;

      &:before {
        content: counters(item, ".") ". ";
      }
    }

    .editor-js-ol {
      @apply pl-5;
    }
  }

  /* Link tool */

  .editor-js-link {
    @apply grid gap-6 overflow-hidden border border-blue-grey-50 rounded effect-00dp p-6;

    grid-template-columns: repeat(2, minmax(50px, auto));

    .editor-js-link-left {
      .editor-js-link-left-title {
        @apply block text-body-1 font-semibold mb-3;
      }

      .editor-js-link-left-description {
        @apply block text-body-2 mb-3;
      }

      .editor-js-link-left-link {
        @apply block text-caption text-blue-grey-400 truncate;
      }
    }

    .editor-js-link-right {
      .editor-js-link-right-image {
        @apply block border border-blue-grey-50 object-cover rounded overflow-hidden effect-01dp;

        min-width: 50px;
        min-height: 50px;
        max-width: 50px;
        max-height: 50px;
      }
    }
  }

  /* Warning tool */

  .editor-js-warning {
    @apply flex space-x-4 bg-tertiary-25 rounded p-4;

    .editor-js-warning-icon {
      @apply flex items-start justify-center min-w-[20px];
    }

    .editor-js-warning-content {
      @apply flex flex-col space-y-1;

      .editor-js-warning-content-title {
        @mixin title-style {
          @apply text-body-2 font-semibold text-tertiary-A400;
        }

        @include title-style;

        * {
          @include title-style;
        }
      }

      .editor-js-warning-content-message {
        @mixin message-style {
          @apply text-body-2 text-tertiary-300;
        }

        @include message-style;

        * {
          @include message-style;
        }
      }
    }
  }

  /* Table tool */

  .editor-js-table {
    @apply border-collapse border w-full;

    .editor-js-table-tbody {
      .editor-js-table-th {
        @apply border font-semibold text-left p-2;
      }

      .editor-js-table-td {
        @apply border p-2;
      }
    }
  }

  /* Image tool */

  .editor-js-figure-image {
    @apply flex flex-col items-start mb-4;

    .editor-js-image {
      @apply overflow-hidden rounded w-max mb-2 cursor-pointer;

      &.withBorder {
        @apply border border-blue-grey-50 effect-01dp;
      }

      &.withBackground {
        @apply bg-blue-grey-50 p-4;
      }

      &.stretched {
        @apply w-full;
      }
    }

    .editor-js-figure-caption {
      @apply w-full text-left text-body-2 text-blue-grey-400 rounded-none border-blue-grey-100 border-l overflow-hidden py-0 px-2;

      /*max-width: var(--caption-width);
      min-width: 300px;*/
    }

    /* Modifiers */

    &.withBorder {
      .editor-js-image {
        @apply border border-blue-grey-50 effect-01dp;
      }
    }

    &.withBackground {
      .editor-js-wrapper-image {
        @apply flex justify-center w-full rounded bg-blue-grey-25 p-4;

        .editor-js-image {
          @apply mb-0 cursor-default;
        }
      }
    }

    &.stretched {
      .editor-js-wrapper-image,
      .editor-js-image {
        @apply w-full;
      }
    }

    &.alignLeft {
      @apply items-start;

      //.editor-js-figure-caption {
      //  @apply text-left;
      //}
    }

    &.alignCenter {
      @apply items-center;

      //.editor-js-figure-caption {
      //  @apply text-center;
      //}
    }

    &.alignRight {
      @apply items-end;

      //.editor-js-figure-caption {
      //  @apply text-right;
      //}
    }

  }

  /* Attaches tool */

  .editor-js-attaches {
    @apply flex items-center justify-between space-x-4 border border-blue-grey-50 text-blue-grey-300 rounded effect-00dp p-3;

    .editor-js-attaches-file-icon {
      @apply flex items-center justify-center relative;

      &::before {
        position: absolute;
        bottom: 7px;
        left: 8.5px;
        font-size: 8px;
        font-weight: 900;
        text-transform: uppercase;
        background: #fff;
        line-height: 150%;
        content: attr(data-extension);
      }
    }

    .editor-js-attaches-file-info {
      @apply flex flex-col flex-1;

      .editor-js-attaches-file-info-title {
        @apply text-subtitle-2;
      }

      .editor-js-attaches-file-info-size {
        @apply text-body-2 text-blue-grey-400;
      }
    }

    .editor-js-attaches-file-download {
      @apply flex items-center justify-center;
    }
  }
}
