.snack-progress {
  animation: snack-progress-fill var(--timeout) linear 1;
}

@keyframes snack-progress-fill {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
