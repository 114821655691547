@charset "utf-8";

/* https://tailwindcss.com/ */

@import "styles/vendors/_tailwind.css";

/* https://sass-guidelin.es */

/* ABSTRACTS */

@import "styles/abstracts/shadows";

/* BASE */

@import "styles/base/reset";
@import "styles/base/typography";

/* COMPONENTS */

@import "styles/components/buttons";
@import "styles/components/calendar";
@import "styles/components/inputs";
@import "styles/components/overlay";
@import "styles/components/labels";
@import "styles/components/snack";
@import "styles/components/tabs";

/* LAYOUT */

@import "styles/layout/scrollbars";
@import "styles/layout/utilities";

/* PAGES */

@import "styles/pages/auth";
@import "styles/pages/dashboard";
@import "styles/pages/dashboard-profile";
@import "styles/pages/dashboard-team";
@import "styles/pages/error";
@import "styles/pages/ui";

/* SHARED */

@import "styles/shared/app-error-label";
@import "styles/shared/app-loader-http";
@import "styles/shared/app-release-notes";
@import "styles/shared/app-skill-tasks-description-editor";
@import "styles/shared/editor/app-skill-tasks-description-editor-attaches";
@import "styles/shared/editor/app-skill-tasks-description-editor-code";
@import "styles/shared/editor/app-skill-tasks-description-editor-embed";
@import "styles/shared/editor/app-skill-tasks-description-editor-header";
@import "styles/shared/editor/app-skill-tasks-description-editor-image";
@import "styles/shared/editor/app-skill-tasks-description-editor-inline-link";
@import "styles/shared/editor/app-skill-tasks-description-editor-marker";
@import "styles/shared/editor/app-skill-tasks-description-editor-nested-list";
@import "styles/shared/editor/app-skill-tasks-description-editor-paragraph-with-alignment";
@import "styles/shared/editor/app-skill-tasks-description-editor-quote";
@import "styles/shared/editor/app-skill-tasks-description-editor-table";
@import "styles/shared/editor/app-skill-tasks-description-editor-underline";
@import "styles/shared/editor/app-skill-tasks-description-editor-warning";
@import "styles/shared/app-skill-tasks-description-html";
@import "styles/shared/app-team-add";
