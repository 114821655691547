@use "../abstracts/variables";

/* EditorJS "native" updates */

/* https://www.figma.com/file/JghO5FySoB07H4BfTqNa8m/Dabster-1.0-%7C-Design-Kit?node-id=0%3A2347 */

.ce-toolbox {
  right: 52px;
}

/* Fullscreen */

.fullscreen-on {
  .ce-block {
    max-width: calc(var(--test-width) - 3rem - 50px);
    @media screen and (max-width: 650px) {
      max-width: calc(var(--test-width) - 3rem);
    }
  }
}

.fullscreen-off {
  .ce-block {
    max-width: calc(var(--float-width) - 3rem - 50px);
    @media screen and (max-width: 650px) {
      max-width: calc(var(--float-width) - 3rem);
    }
  }
}

.codex-editor--narrow .ce-block--focused {
  margin-right: unset;
  padding-right: unset;
}

.ce-toolbar {
  z-index: 3;
}
.ce-block__content,
.ce-toolbar__content {
  max-width: 100%;
}

/* Styles */

.ce-block {
  @apply my-2;

  overflow-wrap: break-word;
}

.ce-block__content {

  /* Apply dark color to all */

  * {
    @apply text-subtitle-1 font-normal text-blue-grey-900;
  }

  /* Headings */

  h1 {
    @apply text-headline-7 font-semibold mt-4 p-0;
  }

  h2 {
    @apply text-subtitle-1 mt-4 p-0;
  }

  b, b * {
    @apply font-semibold;
  }

  a:not(.editor-js-link):not(.editor-js-attaches) {
    @apply text-primary-A400 underline;

    &:hover {
      @apply text-primary-800;
    }
  }

  /* Block tools */

  .cdx-block {
    @apply p-0;

    /* Link tool */

    .link-tool {
      .link-tool__input {
        @apply border border-blue-grey-50 text-body-1;

        &[contentEditable=true]:empty:before {
          @apply text-blue-grey-300;
        }
      }

      .link-tool__content {
        @apply overflow-hidden border border-blue-grey-50 rounded effect-00dp p-6;

        .link-tool__title {
          @apply block text-body-1 font-semibold mb-3;
        }

        .link-tool__description {
          @apply block text-body-2 mb-3;
        }

        .link-tool__anchor {
          @apply block text-caption text-blue-grey-400;
        }

        .link-tool__image {
          @apply block border border-blue-grey-50 object-cover rounded effect-01dp;

          min-width: 50px;
          min-height: 50px;
          max-width: 50px;
          max-height: 50px;
        }
      }
    }
  }

  /* Table tool */

  .tc-wrap {
    @apply mt-0;

    border: 1px solid #e8e8eb;

    .tc-table {
      @apply border-t-0;

      &.tc-table--heading {
        .tc-row:first-child {
          @apply border-b;

          &::after {
            @apply border-b -bottom-px;
          }

          .tc-cell {
            @apply text-body-1 font-semibold;

            &[contentEditable=true]:empty:before {
              @apply text-body-1 font-semibold text-blue-grey-300;
            }
          }
        }

        .tr-row:last-child {
          &::after {
            @apply border-b-0;
          }
        }
      }
    }

    .tc-add-column {
      @apply border-t-0 opacity-50;
    }

    .tc-add-row {
      @apply opacity-50;
    }
  }

  /* Marker tool */

  .cdx-marker {
    @apply bg-secondary-100 rounded-sm px-1;
  }
}

.ce-toolbar__actions {
  right: 3px !important;
}

.skill-task-description-editor {
  .codex-editor {
    .codex-editor__loader {
      @apply h-[10vh];
    }
  }
}
