/* Plugin styles */

.ce-paragraph {
  line-height: 1.6em;
  outline: none;
}
.ce-paragraph--right {
  text-align: right;
}
.ce-paragraph--center {
  text-align: center;
}
.ce-paragraph--left {
  text-align: left;
}

.ce-paragraph--justify {
  text-align: justify;
}

.ce-paragraph[data-placeholder]:empty::before{
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type{
  margin-top: 0;
}

.ce-paragraph p:last-of-type{
  margin-bottom: 0;
}


.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4691f6;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}

/* KW styles */

.ce-paragraph {
  @apply leading-6 p-0;

  &[contentEditable=true]:empty:before {
    @apply text-blue-grey-300;
  }
}
