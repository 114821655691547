.app-team-add {
  .row {
    display: grid;
    grid-template-areas:
    'column-email column-role column-close';
    grid-template-columns: 400px 92px 72px;
    grid-template-rows: 56px;
    grid-gap: 1rem;
  }

  @each $area in ('column-email', 'column-role', 'column-close') {
    .#{$area} {
      grid-area: #{$area};
    }
  }

  ul {
    min-width: 602px;
  }

  .team-add-email-control {
    @apply flex items-center justify-between;

    input {
      @apply pr-36 rounded-r-none;
    }

    button {
      &.btn-squared {
        @apply
        text-button-1
        w-14 h-14
        py-4.5 px-6
        text-white
        bg-primary-A400
        rounded-r;

        &:not(:disabled) {
          &.active,
          &:hover {
            @apply bg-primary-800;
          }
        }

        &:disabled {
          @apply opacity-25 cursor-not-allowed;
        }
      }
    }
  }
}
