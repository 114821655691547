/* https://codepen.io/mildrenben/pen/bdGdOb */

$tabs-underline-height: 2px;
$tabs-count: 10;

.tabs {
  --tabs-count: 0;

  li {
    @for $i from 1 through $tabs-count {
      &:nth-of-type(#{$i}).active {
        ~ .underline {
          left: calc((100% / #{'var(--tabs-count)'}) * #{$i - 1});
        }
      }
    }

    &:first-of-type.active {
      ~ .underline {
        @apply left-0;
      }
    }
  }

  .underline {
    @apply absolute bg-primary-A400 transition-all duration-200 ease-out;

    height: $tabs-underline-height;
    width: calc(100% / #{'var(--tabs-count)'});
    top: calc(100% - #{$tabs-underline-height});
    left: 0;
  }
}
