/* Plugin styles */

.cdx-warning {
  @apply relative flex space-x-4 bg-tertiary-25 rounded p-4 #{'!important'};

  .cdx-warning__icon {
    @apply flex items-start justify-center py-0.5 min-h-[20px];
  }

  .cdx-warning__content {
    @apply flex flex-col space-y-1 w-full;

    .cdx-warning__title {
      @mixin title-style {
        @apply text-body-2 font-semibold text-tertiary-A400;
      }

      @apply border-0 shadow-none py-0.5 px-0;

      @include title-style;

      &[contentEditable=true]:empty:before {
        content: attr(data-placeholder);

        @apply absolute text-tertiary-200;
      }

      &[contentEditable=true]:empty:focus:before {
        @apply hidden;
      }

      * {
        @include title-style;
      }
    }

    .cdx-warning__message {
      @mixin message-style {
        @apply text-body-2 text-tertiary-A200;
      }

      @apply border-0 shadow-none p-0;

      @include message-style;

      &[contentEditable=true]:empty:before {
        content: attr(data-placeholder);

        @apply absolute text-tertiary-100;
      }

      &[contentEditable=true]:empty:focus:before {
        @apply hidden;
      }

      * {
        @include message-style;
      }
    }
  }
}
