/* Define global styles */

hr {
  @apply border-blue-grey-50;
}

h1 {
  @apply text-headline-1;
}

h2 {
  @apply text-headline-2;
}

h3 {
  @apply text-headline-3;
}

h4 {
  @apply text-headline-4;
}

h5 {
  @apply text-headline-5;
}

h6 {
  @apply text-headline-6;
}

a {
  @apply
  text-primary-A400
  hover:text-primary-800
  transition-all
  ease-linear
  duration-100;

  &.tab {
    &.text-primary-A400 {
      @apply
      hover:text-primary-A400;
    }

    &.text-blue-grey-300 {
      @apply
      hover:text-blue-grey-300;
    }
  }

  &.breadcrumb {
    @apply
    text-blue-grey-400
    hover:text-blue-grey-400
    transition-all
    ease-linear
    duration-100;
  }
}

[contenteditable] {
  @apply outline-[0px];

  &[placeholder]:empty::before {
    content: attr(placeholder);

    @apply text-blue-grey-400;
  }
}

.embed {
  @apply relative pt-[56.25%];

  .embed-youtube, .embed-unknown {
    @apply absolute inset-0 w-full h-full;
  }
}

summary {
  outline: none;
}

summary::-webkit-details-marker {
  display: none;
}

[data-hint-follow]:after {
  content: attr(data-hint-follow);

  @apply fixed hidden rounded-md border border-primary-A100 effect-00dp text-blue-grey-600 bg-white text-caption w-[228px] px-2 py-1;
}
