@use "sass:string";

/* https://developer.mozilla.org/ru/docs/Web/CSS/font-weight */

@each $weight, $name in (
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
) {
  @font-face {
    font-family: 'Inter';
    src: url('./../../assets/fonts/inter/Inter-#{$name}.woff') format('woff'),
         url('./../../assets/fonts/inter/Inter-#{$name}.woff2') format('woff2');
    font-weight: $weight;
    font-style: normal;
  }
}

@layer components {
  .text-headline-1 {
    font-family: 'Inter', sans-serif;
    font-size: 90px;
    line-height: 112px;
    letter-spacing: -1.5px;

    @apply font-light text-blue-grey-900;
  }

  .text-headline-2 {
    font-family: 'Inter', sans-serif;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;

    @apply font-normal text-blue-grey-900;
  }

  .text-headline-3 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    line-height: 56px;

    @apply font-bold text-blue-grey-900;
  }

  .text-headline-4 {
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    line-height: 40px;

    @apply font-bold text-blue-grey-900;
  }

  .text-headline-5 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-headline-6 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.18px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-headline-7 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15px;

    @apply font-medium text-blue-grey-900;
  }

  .text-subtitle-1 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-subtitle-2 {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-body-1 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;

    @apply font-normal text-blue-grey-900;
  }

  .text-body-2 {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;

    @apply font-normal text-blue-grey-900;
  }

  .text-button-1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-button-2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;

    @apply font-semibold text-blue-grey-900;
  }

  .text-caption {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;

    @apply text-blue-grey-900;
  }

  .text-overline {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    @apply font-semibold text-blue-grey-900;
  }
}
