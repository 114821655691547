/* Plugin styles */

.cdx-attaches {
  &.cdx-attaches--with-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;
    border: 1px solid #e6e9eb;
    border-radius: 3px;
    background: #fff;
    column-gap: 1rem;

    @apply border-blue-grey-50 rounded-md p-4;

    .cdx-attaches__file-info {
      flex-grow: 8;
      max-width: calc(100% - 80px);

      .cdx-attaches__size {
        @apply text-body-2 text-blue-grey-400;

        &::after {
          content: attr(data-size);
          margin-left: 0.2em;
        }
      }

      .cdx-attaches__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        outline: none;
        max-width: 90%;

        @apply text-subtitle-2 truncate;
      }
    }

    .cdx-attaches__download-button {
      display: flex;
      align-items: center;

      svg {
        fill: #7b7e89;
      }
    }

    .cdx-attaches__file-icon {
      position: relative;

      &::before {
        position: absolute;
        bottom: 7px;
        left: 8.5px;
        font-size: 8px;
        font-weight: 900;
        text-transform: uppercase;
        background: #fff;
        line-height: 150%;
        content: attr(data-extension);
      }

      svg {
        fill: currentColor;
      }
    }
  }

  &.cdx-attaches--loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .cdx-attaches__title,
    .cdx-attaches__file-icon,
    .cdx-attaches__size,
    .cdx-attaches__download-button,
    .cdx-attaches__button {
      opacity: 0;
      font-size: 0;
    }
  }

  .cdx-attaches__button {
    display: flex;
    align-items: center;
    justify-content: center;

    @apply border-blue-grey-50 rounded-md p-4;

    svg {
      height: 14px;
      margin-top: 0;
      margin-right: 8px;
    }

    &:hover {
      color: #393f52;
    }
  }
}

/* KW styles */

.cdx-attaches {
  .cdx-button {
    &.cdx-attaches__button {
      @apply overflow-hidden border border-blue-grey-50 text-blue-grey-300 rounded effect-00dp p-3;

      &:hover {
        @apply bg-blue-grey-25 text-blue-grey-900;

        svg {
          @apply opacity-100;
        }
      }

      svg {
        @apply opacity-50;
      }
    }
  }

  &.cdx-attaches--loading {
    &.cdx-loader {
      @apply overflow-hidden border border-blue-grey-50 text-blue-grey-300 rounded effect-00dp p-3;
    }
  }

  &.cdx-attaches--with-file {
    @apply overflow-hidden border border-blue-grey-50 text-blue-grey-300 rounded effect-00dp p-4;

    .cdx-attaches__file-icon {
      & * {
        color: inherit;

      }
    }
  }
}
