/* Plugin styles */

.cdx-quote-icon svg {
  transform: rotate(180deg);
}

.cdx-quote {
  margin: 0;
}

.cdx-quote__text {
  min-height: 158px;
  margin-bottom: 10px;
}

.cdx-quote__caption {}

.cdx-quote [contentEditable=true][data-placeholder]::before{
  position: absolute;
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.cdx-quote [contentEditable=true][data-placeholder]:empty::before {
  opacity: 1;
}

.cdx-quote [contentEditable=true][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.cdx-quote-settings {
  display: flex;
}

.cdx-quote-settings .cdx-settings-button {
  width: 50%;
}

/* KW styles */

.cdx-quote {
  @apply bg-blue-grey-25 border border-blue-grey-100 rounded p-4 #{'!important'};

  .cdx-input.cdx-quote__text {
    @apply min-h-0 border-0 border-b border-b-blue-grey-100 outline-none shadow-none p-0 pb-4 mb-4;

    &[contentEditable=true]:empty:before {
      @apply text-blue-grey-300;
    }

    &[contentEditable=true]:not(:empty):before {
      content: "“";

      @apply inline-block text-blue-grey-900 opacity-100 text-center w-3;
    }

    &[contentEditable=true]:not(:empty):after {
      content: "”";

      @apply inline-block text-blue-grey-900 opacity-100 text-center w-3;
    }

    &.text-center {
      &[contentEditable=true]:empty:before {
        @apply inline -translate-x-full;
      }
    }
  }

  .cdx-input.cdx-quote__caption {
    @apply min-h-0 border-0 outline-none shadow-none text-body-2 italic p-0;

    &[contentEditable=true]:empty:before {
      @apply text-blue-grey-300;
    }

    &[contentEditable=true]:not(:empty):before {
      content: "—";

      @apply inline-block text-blue-grey-900 opacity-100 w-5;
    }
  }
}
