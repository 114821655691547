.app-dashboard-invite {
  .row {
    display: grid;
    grid-template-areas:
      'column-user column-role column-action column-close';
    grid-template-columns: 320px minmax(175px, 202px) minmax(286px, 404px) 36px;
    grid-template-rows: 56px;
    grid-gap: 1rem;

    @apply border-b border-blue-grey-50 items-center px-4;

    @each $area in ('column-user', 'column-role', 'column-action', 'column-close') {
      .#{$area} {
        grid-area: #{$area};
      }
    }

    &:last-child {
      @apply border-b-0;
    }

    &.disabled:before {
      content: '';

      @apply absolute w-full h-full bg-white opacity-50 cursor-not-allowed z-[1];
    }
  }

  &.collapse {
    @apply border-t border-blue-grey-50;
  }

  &.accordion {
    max-height: var(--max-height);

    @apply overflow-hidden duration-500;
  }
}

.app-dashboard-team {
  .row {
    display: grid;
    grid-template-areas:
      'column-user column-role column-deadline column-skills column-close';
    grid-template-columns: 320px minmax(175px, 202px) repeat(2, minmax(135px, 202px)) 36px;
    grid-template-rows: 56px;
    grid-gap: 1rem;

    @apply border-b border-blue-grey-50 items-center px-4;

    @each $area in ('column-user', 'column-role', 'column-deadline', 'column-skills', 'column-close') {
      .#{$area} {
        grid-area: #{$area};
      }
    }

    &:last-child {
      @apply border-b-0;
    }

    &.disabled:before {
      content: '';

      @apply absolute w-full h-full bg-white opacity-50 cursor-not-allowed z-[1];
    }
  }
}
