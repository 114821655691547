$progress-height: 2px;
$progress-flare-width: 40px;

.loader-http {
  @apply overflow-hidden absolute top-0 left-0 w-full;
  height: $progress-height;

  .loader-http-inner {
    @apply delay-200 relative bg-primary-A400;
    --progress-width: 0;
    width: var(--progress-width);
    height: $progress-height;
    transition: 0.2s;

    &.flare::after {
      @apply absolute top-0 left-0 opacity-75 bg-white;
      width: $progress-flare-width;
      height: $progress-height;
      content: '';
      background: linear-gradient(130deg, transparent 0%, var(--white) 50%, transparent 100%);

      animation-name: flare;
      animation-duration: 1.5s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;

      @keyframes flare {
        from {
          left: -#{$progress-flare-width};
        }

        to {
          left: calc(100% + #{$progress-flare-width});
        }
      }
    }
  }
}
