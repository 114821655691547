.form-control {
  input {
    @apply
    text-body-1
    border-0
    border-shadow-default
    placeholder-transparent
    overflow-ellipsis
    rounded
    w-full
    pt-6
    pb-2
    px-4;

    &:focus {
      @apply outline-none border-shadow-focus;
    }

    &.ng-touched {
      &.ng-invalid {
        @apply border-shadow-error;

        & ~ label.label {
          @apply text-tertiary-A400
        }
      }

      &.ng-valid {

      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition-property: background-color;
      transition-delay: 1000000000s;
    }

    &.host {
      @apply pl-4 pr-28;
    }

    &:disabled {
      @apply opacity-50 select-none;
    }
  }

  > figure {
    // same as line-height of .text-body-1

    height: 24px;
    width: 24px;

    @apply box-content top-1/2 -translate-y-1/2;

    &:not(.password) {
      @apply pointer-events-none;
    }

    &.password {
      @apply cursor-pointer select-none;
    }

    i {
      @apply top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
    }

    &.i-left {
      @apply left-4;

      & ~ input {
        @apply pl-12 pr-4;
      }

      & ~ label.label {
        @apply left-12;
      }
    }

    &.i-right {
      @apply right-4;

      & ~ input {
        @apply pr-12 pl-4;
      }
    }
  }

  label {
    @apply absolute pointer-events-none transition-all;

    &.label {
      @apply
      block
      left-4
      top-2
      text-blue-grey-400
      text-caption;
      max-width: calc(100% - theme('spacing.8'));
    }

    &.host {
      @apply
      right-4
      bottom-2
      text-blue-grey-400
      text-body-1;
    }
  }
}

.form-control-unset {
  @apply flex;

  textarea,
  input {
    @apply bg-transparent border-shadow-transparent placeholder-blue-grey-400 border-0 outline-none resize-none;

    &:focus {
      @apply border-0 border-shadow-transparent outline-none;
    }
  }
}

.form-control-textarea {
  textarea {
    @apply
    text-body-1
    border-0
    border-shadow-default
    overflow-ellipsis
    rounded
    w-full
    p-4;

    &:focus {
      @apply outline-none border-shadow-focus;
    }

    &.ng-touched {
      &.ng-invalid {
        @apply border-shadow-error;

        & ~ label.label {
          @apply text-tertiary-A400
        }
      }

      &.ng-valid {

      }
    }

    &:disabled {
      @apply opacity-50 select-none;
    }
  }
}

.checkbox {
  @apply flex items-center;

  & > input {
    @apply hidden;

    & + label {
      @apply
      relative
      inline-flex
      items-center
      text-button-1
      text-blue-grey-400
      cursor-pointer
      select-none;
    }

    & + label::before {
      @apply w-[18px] h-[18px] mr-2 bg-blue-grey-400;

      content: '';
      mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM16 16V2H2V16H16Z' fill='%2378909C'/%3E%3C/svg%3E ");
    }

    &:checked + label {
      &::before {
        @apply bg-primary-A400;

        mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_5463_22116' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H16C17.11 0 18 0.9 18 2V16C18 17.1 17.11 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0ZM2.70549 8.29451C2.31586 8.68414 2.31586 9.31586 2.70549 9.70549L6.29289 13.2929C6.68342 13.6834 7.31658 13.6834 7.70711 13.2929L15.2954 5.70461C15.6849 5.31506 15.6857 4.68343 15.2975 4.29251C14.9074 3.89963 14.2719 3.89811 13.8804 4.28961L7.70612 10.4639C7.31598 10.854 6.68358 10.8545 6.29289 10.4649L4.11549 8.29352C3.7257 7.90482 3.09474 7.90526 2.70549 8.29451Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_5463_22116)'%3E%3Crect x='-3' y='-3' width='24' height='24' rx='1' fill='%232979FF'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }
}

.masked-checkbox-select {
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_6003_18889' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 3H19C20.11 3 21 3.9 21 5V19C21 20.1 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3ZM5.70549 11.2945C5.31586 11.6841 5.31586 12.3159 5.70549 12.7055L9.29289 16.2929C9.68342 16.6834 10.3166 16.6834 10.7071 16.2929L18.2954 8.70461C18.6849 8.31506 18.6857 7.68343 18.2975 7.29251V7.29251C17.9074 6.89963 17.2719 6.89811 16.8804 7.28961L10.7061 13.4639C10.316 13.854 9.68358 13.8545 9.29289 13.4649L7.11549 11.2935C6.7257 10.9048 6.09474 10.9053 5.70549 11.2945V11.2945Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_6003_18889)'%3E%3Crect width='24' height='24' rx='1' fill='%232979FF'/%3E%3C/g%3E%3C/svg%3E");
}

.masked-checkbox-deselected {
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_6003_18245' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_6003_18245)'%3E%3Crect width='24' height='24' fill='%2378909C'/%3E%3C/g%3E%3C/svg%3E");
}
