@use "../abstracts/variables";

.app-dashboard {
  display: grid;
  grid-template-areas:
    'app-header app-header'
    'app-aside app-content'
    'app-footer app-footer';
  grid-template-columns: minmax(0, theme('spacing.64')) minmax(0, 1fr);
  grid-template-rows: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);

  @screen tablet {
    grid-template-areas:
    'app-header app-header'
    'app-content app-content'
    'app-footer app-footer';
    grid-template-columns: minmax(0, 1fr);
  }

  .app-header {
    grid-area: app-header;

    @apply z-20;
  }

  .app-aside {
    grid-area: app-aside;

    @screen tablet {
      @apply fixed top-0 left-0 block z-30 h-full;
    }
  }

  .app-content {
    grid-area: app-content;
  }

  .app-footer {
    grid-area: app-footer;

    @apply z-20;
  }
}

.app-dashboard.without-team {
  grid-template-areas:
    'app-header app-header'
    'app-content app-content'
    'app-footer app-footer';
  grid-template-columns: minmax(0, 1fr);
}
