@import "~nice-select2/dist/css/nice-select2.css";

$fontSize: 13px;
$lineHeight: 20px;
$lineNumberWidth: 40px;
$color: #fff;
$fontFamily: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

.codeflask-description-wrapper {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  background-color: #f0f2f5;
  margin-bottom: 10px;
  position: relative;

  .codeflask-description {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    background: none;

    .token.punctuation {
      color: #4a4a4a;
    }
    .token.keyword {
      color: #8500ff;
    }
    .token.operator {
      color: #ff5598;
    }
    .token.string {
      color: #41ad8f;
    }
    .token.comment {
      color: #9badb7;
    }
    .token.function {
      color: #8500ff;
    }
    .token.boolean {
      color: #8500ff;
    }
    .token.number {
      color: #8500ff;
    }
    .token.selector {
      color: #8500ff;
    }
    .token.property {
      color: #8500ff;
    }
    .token.tag {
      color: #8500ff;
    }
    .token.attr-value {
      color: #8500ff;
    }
  }
  .codeflask-description, .codeflask-description * {
    box-sizing: border-box;
  }
  .codeflask-description__pre {
    z-index: 3;
    overflow: hidden;
  }
  .codeflask-description__code {
    font-family: $fontFamily;
  }
  .codeflask-description__flatten {
    padding: 10px;
    font-size: $fontSize;
    line-height: $lineHeight;
    white-space: pre;
    overflow: auto;
    margin: 0 !important;
    outline: none;
    text-align: left;
  }
  .codeflask-description__line-highlight {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: $lineHeight;
    background: rgba(0,0,0,0.1);
    z-index: 1;
  }
  .codeflask-description__lines {
    background-color: #dcdfe6;
    padding: 10px 4px;
    font-size: 12px;
    line-height: $lineHeight;
    font-family: 'Cousine', monospace;
    position: absolute;
    left: 0;
    top: 0;
    width: $lineNumberWidth;
    height: 100%;
    text-align: right;
    color: #999;
    z-index: 2;
  }
  .codeflask-description__lines__line {
    display: block;
  }
  .codeflask-description.codeflask-description--has-line-numbers {
    padding-left: $lineNumberWidth;
  }
  .codeflask-description-lang-display {
    position: absolute;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    color: #999;
    background-color: #dcdfe6;
    padding: 0 10px;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .codeflask-description.codeflask-description--has-line-numbers:before{
    background-color: #dcdfe6;
  }
}

// Переписываем стили codeflask библиотеки.
.codeflask-wrapper {
  height: 200px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  background-color: #f0f2f5;
  margin-bottom: 10px;
  .codeflask {
    border-radius: 5px;
    background-color: transparent;

    &.codeflask--has-line-numbers:before{
      background-color: #dcdfe6;
    }
    .codeflask__pre {
      width: auto;
    }
    .codeflask__lines {
      width: 39px;
      background-color: #dcdfe6;
      z-index: 3;
    }
  }
  .codeflask-lang-display {
    position: absolute;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    color: #999;
    background-color: #dcdfe6;
    padding: 0 10px;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}

// Применяем шрифты для кода.
.codeflask-wrapper .codeflask .codeflask__flatten,
.codeflask-wrapper .codeflask .codeflask__flatten *,
.codeflask-description-wrapper .codeflask-description .codeflask-description__flatten,
.codeflask-description-wrapper .codeflask-description .codeflask-description__flatten * {
  font-size: 13px;
  line-height: $lineHeight;
  font-family: $fontFamily;
}
.codeflask-wrapper .codeflask .codeflask__lines *,
.codeflask-description-wrapper .codeflask-description .codeflask-description__lines * {
  font-size: 12px;
  line-height: $lineHeight;
  font-family: 'Cousine', monospace;
  color: #999;
}

// Панель с настройками.
.ce-settings {
  .settings-box {
    @apply flex;

    .nice-select {
      @apply flex items-center text-base text-blue-grey-900 w-full;
    }

    .nice-select-dropdown {
      .nice-select-search-box > .nice-select-search {
        @apply text-base text-blue-grey-900 m-0;
      }
      .nice-select-search-box > .nice-select-search::placeholder {
        @apply text-base;

        color: #707684;
      }

      .list > li {
        @apply flex items-center text-sm px-2.5;
      }

    }
  }
}
